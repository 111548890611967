<template>
  <div class="dog-top-actions">
    <el-tooltip effect="dark" content="Add Dog" placement="left">
      <font-awesome-icon
        :icon="['fa', 'plus-circle']"
        class="addDogIcon"
        @Click="handleAddDog"
      ></font-awesome-icon>
    </el-tooltip>
  </div>
  <div v-if="!ownerDogs"><base-spinner /></div>
  <div class="dog-cards" v-else-if="ownerDogs && ownerDogs.length > 0">
    <DogCard v-for="dog in ownerDogs" :key="dog._id" :dog="dogModel(dog)" />
  </div>
  <div v-else>
    <h1>No dogs found</h1>
  </div>
</template>

<script>
import DogCard from "../components/dog/DogCard.vue";
import { Dog } from "@/models/dogModel";

export default {
  components: { DogCard },
  computed: {
    ownerDogs() {
      const userProfile = this.$store.getters["user/getUserProfile"];
      return userProfile.dogs;
    },
  },
  methods: {
    handleAddDog() {
      this.$router.push({ name: "adddog", params: { addOrEdit: "add" } });
    },
    dogModel(dog) {
      return new Dog(dog);
    },
  },
};
</script>

<style lang="scss" scoped>
.dog-cards {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: calc(100% - 120px);
  margin: 0 auto;
}
</style>
