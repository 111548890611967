<template>
  <div class="dog-medal-counts">
    <el-tooltip effect="dark" content="Titles" placement="left">
      <el-badge :value="dog.titles.length" type="primary">
        <font-awesome-icon :icon="['fa', 'trophy']"></font-awesome-icon>
      </el-badge>
    </el-tooltip>
    <!-- <el-badge :value="placedFinishes" type="primary">
      <font-awesome-icon :icon="['fa', 'medal']"></font-awesome-icon>
    </el-badge> -->
    <el-tooltip effect="dark" content="Trials" placement="left">
      <el-badge :value="dog.trials.length" type="primary">
        <font-awesome-icon :icon="['fa', 'running']"></font-awesome-icon>
      </el-badge>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: { dog: Object, isLoading: Boolean },
  computed: {
    placedFinishes(dog) {
      return !this.isLoading ? this.dog.getPlacedFinishes(dog) : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.dog-medal-counts {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 31px;
  padding-top: 15px;
  background: $primary-20;

  svg {
    font-size: 1.5rem;
    color: $tertiary-75;
  }
}
</style>
