<template>
  <el-card class="dog-card" @click="handleDogDetail">
    <DogAvatar :dog="dog" avatarSize="140" @imageLoaded="imageLoaded" />

    <el-skeleton
      :rows="0"
      :loading="isLoading"
      animated
      style="margin-top: 10px"
    >
      <template #template>
        <el-skeleton-item variant="h1"></el-skeleton-item>
        <el-skeleton-item class="card-stats" variant="text"></el-skeleton-item>
      </template>
    </el-skeleton>

    <h1 v-show="!isLoading">{{ dog.formalName }}</h1>
    <DogMedalCounts :isLoading="isLoading" :dog="dog" />
  </el-card>
</template>

<script>
import DogAvatar from "@/ui/DogAvatar";
import DogMedalCounts from "./DogMedalCounts.vue";

export default {
  props: { dog: Object },
  components: { DogAvatar, DogMedalCounts },

  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    placedFinishes(dog) {
      return !this.isLoading ? this.dog.getPlacedFinishes(dog) : 0;
    },
  },
  methods: {
    imageLoaded() {
      this.isLoading = false;
    },
    handleDogDetail() {
      this.$router.push({
        name: "dogDashboard",
        params: { dogId: this.dog._id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dog-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 246px;
  height: 267px;
  margin: 60px 60px 30px;
  text-align: center;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }

  h1 {
    margin-top: 8px;
    margin-bottom: 20px;
    color: $secondary;
  }
}

.card-stats {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 31px;
  padding-top: 15px;
  background: $primary-20;

  svg {
    font-size: 1.5rem;
    color: $tertiary-75;
  }
}
</style>
